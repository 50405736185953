import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    year: requiredString(),
    tutor: z
      .object({
        id: requiredString(),
        full_name: requiredString(),
        first_name: requiredString(),
        last_name: requiredString()
      })
      .nullable()
      .optional(),
    number_of_students: z.number()
  })
)

export type Class = {
  id: string
  name: string
  year: string
  tutor?: {
    id: string
    fullName: string
  }
  numberOfStudents: number
}

const parseResponse = (response: z.infer<typeof responseSchema>): Class[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    year: item.year,
    tutor: item.tutor
      ? {
          id: item.tutor?.id,
          fullName: item.tutor?.full_name
        }
      : undefined,
    numberOfStudents: item.number_of_students
  }))

type Params = {
  id?: string[]
  year?: string[]
  tutorId?: string[]
  studentId?: string[]
  pageSize?: number | 'max'
  page?: number
}

const useStudentClasses = (params: Params = {}) =>
  useQuery({
    queryKey: ['panelClassesList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelClassesList({
        id: params.id,
        page: params.page,
        page_size: params.pageSize,
        student_id: params.studentId,
        tutor_id: params.tutorId,
        year: params.year,
        fetchKeys: {
          id: true,
          name: true,
          tutor: true,
          year: true,
          number_of_students: true
        }
      })

      const parsed = parseResponse(responseSchema.parse(response.results))
      return {
        list: parsed,
        count: response.count
      }
    }
  })

export default useStudentClasses

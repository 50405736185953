import { getRouteApi } from '@tanstack/react-router'
import { isWithinInterval } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIcon from '@/assets/icons/check.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import ConfirmModal from '@/components/ConfirmModal'
import LinkText from '@/components/LinkText/LinkText'
import type { SelectOption } from '@/components/Select/Select'
import Table, { type TableAction, type Column } from '@/components/Table/Table'
import Tag from '@/components/Tag/Tag'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { toast } from '@/hooks/useToast'
import { studentsQueryOptions } from '@/queries/studentsQueryOptions'
import useSemesters from '@/queries/useSemesters'
import { formatDate } from '@/utils/format-date'
import { getValue } from '@/utils/get-value'

import AbsenceExpandedRow from '../components/AbsenceExpandedRow'
import ManageAbsenceModal from '../components/ManageAbsenceModal'
import { type AbsencesFiltersKeys } from '../constants/absences-filters'
import { absencesStatusTags } from '../constants/absences-status-tags'
import useMarkAsPresent from '../mutations/useMarkAsPresent'
import { absenceClassesQueryOptions } from '../queries/absenceClassesQueryOptions'
import useAbsences, { type Absence } from '../queries/useAbsences'
import useAbsencesStatusOptions from '../queries/useAbsencesStatusOptions'
import { type AbsenceStatus } from '../types/absences-status'

const routeApi = getRouteApi('/_auth/absences/')

const AbsencesListView = () => {
  const { t } = useTranslation('absences')
  const {
    page,
    pageSize,
    semester,
    students,
    classes,
    status,
    dateAfter,
    dateBefore
  } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const {
    data: absences,
    isLoading: isAbsencesLoading,
    refetch: refetchAbsenceList
  } = useAbsences({
    semesterId: semester,
    classesId: classes?.map(getValue),
    status,
    studentsId: students?.map(getValue),
    dateAfter,
    dateBefore
  })

  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const { data: statuses, isLoading: isStatusesLoading } =
    useAbsencesStatusOptions()

  const [isMarkAsPresentModalOpen, setIsMarkAsPresentModalOpen] =
    useState(false)
  const [currentAbsence, setCurrentAbsence] = useState<Absence>()
  const [isManageAbsenceModalOpen, setIsManageAbsenceModalOpen] =
    useState(false)

  const closeMarkAsPresentModal = () => {
    setIsMarkAsPresentModalOpen(false)
    setCurrentAbsence(undefined)
  }

  const { mutate: markAsPresent } = useMarkAsPresent({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('notification.successfully-marked-as-present')
      })
      refetchAbsenceList()
      setIsManageAbsenceModalOpen(false)
      closeMarkAsPresentModal()
    },
    onError: () => {
      toast({
        variant: 'success',
        title: t('notification.failed-marked-as-present')
      })
      closeMarkAsPresentModal()
    }
  })

  const tableColumns: Column<Absence>[] = [
    {
      key: 'student',
      title: t('header.student'),
      cellDataTestId: 'student-name',
      width: 200,
      render: (value: Absence) => (
        <LinkText
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: value.student.id
          }}
        >
          {value.student.name}
        </LinkText>
      )
    },
    {
      key: 'class',
      title: t('header.class'),
      cellDataTestId: 'class-name',
      width: 100,
      render: (value: Absence) => (
        <LinkText
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: value.student.studentClass.id
          }}
        >
          {value.student.studentClass.name}
        </LinkText>
      )
    },
    {
      dataIndex: 'startDate',
      key: 'startDate',
      title: t('header.start-date'),
      cellDataTestId: 'start-date',
      width: 170,
      render: formatDate
    },
    {
      dataIndex: 'endDate',
      key: 'endDate',
      width: 170,
      title: t('header.end-date'),
      cellDataTestId: 'end-date',
      render: formatDate
    },
    {
      dataIndex: 'affectedLessons',
      key: 'affectedLessons',
      width: 170,
      title: t('header.affected-lesson'),
      cellDataTestId: 'affected-lesson'
    },
    {
      dataIndex: 'status',
      key: 'status',
      cellDataTestId: 'status',
      title: t('header.status'),
      width: 160,
      render: (value: AbsenceStatus) => <Tag {...absencesStatusTags[value]} />
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        semester,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const handleChangeFilter = (
    key: AbsencesFiltersKeys,
    value?: SelectOption<string> | SelectOption<string>[] | string[] | Date
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const isDateInsideSelectedSemester = (date: Date) => {
    const selectedSemester = semesters?.list.find(item => item.id === semester)

    if (!selectedSemester) return
    return isWithinInterval(date, {
      start: selectedSemester.startDate,
      end: selectedSemester.endDate
    })
  }

  const shouldDateBeDisabled = (date: Date) =>
    !isDateInsideSelectedSemester(date)

  const handleCloseManageAbsenceModal = () => {
    setIsManageAbsenceModalOpen(false)
    refetchAbsenceList()
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: semester,
        placeholder: t('label.semester'),
        onChange: value => {
          navigate({
            search: {
              semester: value,
              page: 1,
              pageSize: DEFAULT_PAGE_SIZE
            }
          })
        }
      }
    },
    {
      label: t('label.student'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'student',
        multiple: true,
        value: students,
        queryOptions: studentsQueryOptions,
        dataTestId: 'student-filter',
        placeholder: t('label.student'),
        onChange: value => handleChangeFilter('students', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'classId',
        dataTestId: 'class-filter',
        multiple: true,
        value: classes,
        queryOptions: absenceClassesQueryOptions,
        placeholder: t('label.class'),
        onChange: value => handleChangeFilter('classes', value)
      }
    },
    {
      label: t('label.start-date'),
      variant: 'date-range',
      size: 'wide',
      filterProps: {
        id: 'range',
        value: {
          from: dateAfter ? new Date(dateAfter) : undefined,
          to: dateBefore ? new Date(dateBefore) : undefined
        },
        disabledDate: shouldDateBeDisabled,
        onChange: value => {
          handleChangeFilter('dateAfter', value?.from)
          handleChangeFilter('dateBefore', value?.to)
        }
      }
    },
    {
      label: t('label.status'),
      variant: 'multiselect',
      filterProps: {
        id: 'status',
        multiple: true,
        loading: isStatusesLoading,
        options: statuses || [],
        value: status,
        dataTestId: 'status-filter',
        placeholder: t('label.status'),
        onChange: value => handleChangeFilter('status', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(
    filter =>
      (filter.filterProps.value &&
        filter.filterProps.id !== 'semester' &&
        filter.variant !== 'date-range') ||
      (filter.variant === 'date-range' &&
        (filter.filterProps.value?.to || filter.filterProps.value?.from))
  )

  const actions: TableAction<Absence>[] = [
    {
      icon: <CheckIcon />,
      text: t('button.mark-as-present'),
      onClick: value => {
        setCurrentAbsence(value)
        setIsMarkAsPresentModalOpen(true)
      }
    },
    {
      icon: <EditIcon />,
      onClick: value => {
        setCurrentAbsence(value)
        setIsManageAbsenceModalOpen(true)
      },
      text: t('button.manage')
    }
  ]

  return (
    <BasicLayout
      header={t('header.absences')}
      moduleName={t('header.academics', { ns: 'common' })}
    >
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        id="absences-table"
        actions={actions}
        isLoading={isAbsencesLoading}
        data={absences?.list || []}
        columns={tableColumns}
        pagination={{
          count: absences?.count,
          pageSize,
          page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        expandedRowRender={record => <AbsenceExpandedRow absence={record} />}
      />

      <ManageAbsenceModal
        open={isManageAbsenceModalOpen}
        onOpenChange={setIsManageAbsenceModalOpen}
        absenceId={currentAbsence?.id}
        markAsPresent={() => setIsMarkAsPresentModalOpen(true)}
        onClose={handleCloseManageAbsenceModal}
      />

      <ConfirmModal
        id="confirm-mark-as-present-modal"
        header={t('header.you-want-to-mark-absences-on-present', {
          DATE: formatDate(currentAbsence?.startDate),
          STUDENT_NAME: currentAbsence?.student.name
        })}
        subheader={
          <>
            {t('text.will-remove-absence-from-attendance')} <br />
            {t('text.action-can-not-be-undone')}
          </>
        }
        onConfirm={() => {
          if (!currentAbsence) return
          markAsPresent({ id: currentAbsence?.id })
        }}
        open={isMarkAsPresentModalOpen}
        onOpenChange={setIsMarkAsPresentModalOpen}
      />
    </BasicLayout>
  )
}
export default AbsencesListView

import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '@/assets/icons/add.svg?react'
import Button from '@/components/Button/Button'
import SplittedHeader from '@/components/SplittedHeader/SplittedHeader'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters from '@/components/common/Filters/Filters'
import useSemesters from '@/queries/useSemesters'
import { getValue } from '@/utils/get-value'

import styles from './AvailabilityManagementView.module.scss'
import AvailabilityLegend from '../components/AvailabilityLegned/AvailabilityLegend'
import AvailabilityModal from '../components/AvailabilityModal/AvailabilityModal'
import AvailiabilityTable from '../components/AvailabilityTable/AvailabilityTable'
import useAvailabilityMangementFilters from '../hooks/useAvailabilityManagementFilters'
import useAvailabilitiesList from '../queries/useAvailabilityList'

const routeApi = getRouteApi('/_auth/availability-management/')

const AvailabilityManagementView = () => {
  const { t } = useTranslation('availabilityManagement')
  const { data: semesters } = useSemesters()
  const { semester, page, pageSize, name, role } = routeApi.useSearch()

  const { filters, handleClearAll, isSomeFilterSelected } =
    useAvailabilityMangementFilters()

  const { data: availability, isLoading } = useAvailabilitiesList({
    semesterId: semester,
    userId: name?.map(getValue),
    role,
    page,
    pageSize
  })

  const selectedSemesterName = semesters?.list.find(
    s => s.id === semester
  )?.name

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <BasicLayout
      moduleName={t('header.administration', { ns: 'common' })}
      header={
        <h1 className={styles.header}>
          <SplittedHeader
            firstPart={t('header.availability-management')}
            secondPart={selectedSemesterName || ''}
          />
        </h1>
      }
    >
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          <Button onClick={() => setIsModalOpen(true)} icon={<PlusIcon />}>
            {t('button.set-availability')}
          </Button>
        }
      />
      <AvailabilityLegend />
      <AvailiabilityTable
        data={availability?.list || []}
        isLoading={isLoading}
        count={availability?.count || 0}
      />
      <AvailabilityModal onOpenChange={setIsModalOpen} open={isModalOpen} />
    </BasicLayout>
  )
}

export default AvailabilityManagementView

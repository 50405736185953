import i18n from '@/i18n'

export const getDaysOfWeek = () =>
  [
    {
      label: i18n.t('label.monday', { ns: 'availabilityManagement' }),
      value: 'monday'
    },
    {
      label: i18n.t('label.tuesday', { ns: 'availabilityManagement' }),
      value: 'tuesday'
    },
    {
      label: i18n.t('label.wednesday', { ns: 'availabilityManagement' }),
      value: 'wednesday'
    },
    {
      label: i18n.t('label.thursday', { ns: 'availabilityManagement' }),
      value: 'thursday'
    },
    {
      label: i18n.t('label.friday', { ns: 'availabilityManagement' }),
      value: 'friday'
    },
    {
      label: i18n.t('label.saturday', { ns: 'availabilityManagement' }),
      value: 'saturday'
    },
    {
      label: i18n.t('label.sunday', { ns: 'availabilityManagement' }),
      value: 'sunday'
    }
  ] as const

import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { orderBy } from 'lodash'
import { type UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type OptionContext } from 'react-select'
import { type z } from 'zod'

import Alert from '@/components/Alert/Alert'
import DatePicker from '@/components/DatePicker/DatePicker'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputPhoneNumber from '@/components/InputPhoneNumber/InputPhoneNumber'
import Loading from '@/components/Loading'
import Select, { type SelectOption } from '@/components/Select/Select'
import SendActivationLinkFormField from '@/components/common/ActivationLinkFormField/SendActivationLinkFormField'
import { CommunitySelect } from '@/components/common/CommunitySelect'
import {
  FormButtons,
  FormLayout,
  FormSection,
  FormSectionFields
} from '@/components/common/FormGrid/FormGrid'
import { LanguageSelect } from '@/components/common/LanguageSelect'
import { NationalitySelect } from '@/components/common/NationalitySelect'
import genderOptions from '@/constants/gender-options'
import { USER_PROFILE } from '@/constants/user-profile'
import { userProfiles } from '@/constants/user-profiles'
import useExternalErrors from '@/hooks/useExternalErrors'
import useClassesOptions, { type Class } from '@/queries/useClassesOptions'
import useRooms from '@/queries/useRooms'
import { type FormError } from '@/types/form-error'
import { type UserProfile } from '@/types/user-profile'
import { type UserStatus } from '@/types/user-status'
import { validateEmailUniqueness } from '@/utils/validate-email-uniqueness'

import styles from './UserForm.module.scss'
import UserRoleGuide from './UserRoleGuide'
import { type AddUserPayload, userFormSchema } from '../constants/user-payload'

export type UserForm = z.infer<typeof userFormSchema>

type UserFormProps = {
  userStatus?: UserStatus
  defaultValues: UserForm
  loading: boolean
  formErrors?: FormError<AddUserPayload>[]
  canRemoveTeacher: boolean
  canRemoveTutor: boolean
  onSubmit: (payload: AddUserPayload) => void
}
const UserForm = (props: UserFormProps) => {
  const { t } = useTranslation(['users', 'common'])

  const form = useForm<UserForm>({
    resolver: zodResolver(userFormSchema),
    mode: 'onBlur',
    defaultValues: props.defaultValues
  })

  useExternalErrors(props.formErrors, form)

  const profile = form.watch('profile')

  const onSubmit = (data: UserForm) => {
    // TODO: Send data to backend in https://panowie.atlassian.net/browse/RAPP-1978
    const payload = {
      email: data.email,
      profile: data.profile,
      sendActivationLink: data.sendActivationLink,
      firstName: data.firstName,
      lastName: data.lastName,
      ahvNumber: data.ahvNumber,
      birthDate: data.birthDate,
      gender: data.gender,
      nationalities: data.nationalities,
      firstLanguage: data.firstLanguage,
      community: data.community,
      phoneNumber: data.phoneNumber,
      emergencyPhoneNumber: data.emergencyPhoneNumber,
      tutor: data.profile.includes(USER_PROFILE.TUTOR)
        ? {
            classes: data.classes
          }
        : null,
      teacher: data.profile.includes(USER_PROFILE.TEACHER)
        ? {
            preferred_rooms: data.preferredRooms
          }
        : null
    }
    props.onSubmit(payload)
  }

  const profilesOptions = userProfiles?.map(option =>
    (option.value === USER_PROFILE.TEACHER && !props.canRemoveTeacher) ||
    (option.value === USER_PROFILE.TUTOR && !props.canRemoveTutor)
      ? {
          ...option,
          isFixed: true
        }
      : option
  )

  const { data: rooms, isLoading: isRoomsLoading } = useRooms()
  const { data: classes, isLoading: isClassesLoading } = useClassesOptions()
  const selectedClasses = form.watch('classes')

  const TEACHING_PROFILES: UserProfile[] = [
    USER_PROFILE.TUTOR,
    USER_PROFILE.TEACHER
  ]

  const alertTextMessages = [
    {
      condition: !props.canRemoveTeacher,
      message: t('help.cannot-remove-teacher')
    },
    {
      condition: !props.canRemoveTutor,
      message: t('help.cannot-remove-tutor')
    }
  ]
    .filter(item => item.condition)
    .map(item => item.message)

  return (
    <Loading spinning={props.loading}>
      <FormLayout form={form} onSubmit={onSubmit}>
        <div className={styles.section}>
          <FormSectionFields>
            <FormField
              control={form.control}
              required
              id="email"
              label={t('label.email')}
              name="email"
              render={({ inputProps }) => (
                <Input
                  {...inputProps}
                  placeholder={t('placeholder.type-email')}
                  type="email"
                  onBlur={() => {
                    inputProps.onBlur?.()
                    setTimeout(() => {
                      validateEmailUniqueness({
                        field: 'email',
                        form,
                        initialValue: props.defaultValues.email
                      })
                    }, 0)
                  }}
                />
              )}
            />
            <div className={styles.sendActivationLink}>
              <SendActivationLinkFormField
                id="send-activation-link"
                name="sendActivationLink"
                control={form.control}
                userStatus={props.userStatus}
              />
            </div>
          </FormSectionFields>
          <FormSectionFields>
            <div className={styles.rolesWrapper}>
              <FormField
                control={form.control}
                id="user-profile"
                required
                label={t('label.user-role')}
                name="profile"
                render={({ inputProps }) => (
                  <Select
                    {...inputProps}
                    placeholder={t('placeholder.select-user-role')}
                    multiple
                    id="user-role"
                    options={profilesOptions || []}
                    clearable={false}
                  />
                )}
              />
              {alertTextMessages.length ? (
                <Alert
                  message={alertTextMessages.map(message => (
                    <span key={message} className={styles.alertText}>
                      {message}
                    </span>
                  ))}
                  variant="info"
                  withoutBackground
                />
              ) : null}
            </div>

            <UserRoleGuide />
          </FormSectionFields>
        </div>
        <BasicInformationFormFields form={form} />
        <FormSection header={t('header.contact-details')}>
          <FormField
            control={form.control}
            id="phone-number"
            label={t('label.default-contact')}
            name="phoneNumber"
            render={({ inputProps }) => <InputPhoneNumber {...inputProps} />}
          />
          <FormField
            control={form.control}
            id="emergency-phone-number"
            label={t('label.emergency-contact')}
            name="emergencyPhoneNumber"
            render={({ inputProps }) => <InputPhoneNumber {...inputProps} />}
          />
        </FormSection>

        {profile.some(element => TEACHING_PROFILES.includes(element)) ? (
          <FormSection header={t('header.academics')}>
            {profile.includes(USER_PROFILE.TEACHER) ? (
              <FormField
                control={form.control}
                id="preferred-rooms"
                label={t('label.preferred-room')}
                name="preferredRooms"
                render={({ inputProps }) => (
                  <Select
                    {...inputProps}
                    options={rooms?.options || []}
                    multiple
                    loading={isRoomsLoading}
                    placeholder={t('placeholder.select-preferred-room')}
                  />
                )}
              />
            ) : null}

            {profile.includes(USER_PROFILE.TUTOR) ? (
              <FormField
                control={form.control}
                id="classes"
                label={t('label.class')}
                name="classes"
                render={({ inputProps }) => (
                  <Select
                    {...inputProps}
                    options={
                      orderBy(classes, 'tutor', 'desc')?.map(item => ({
                        label: item.name,
                        value: item.id
                      })) || []
                    }
                    multiple
                    loading={isClassesLoading}
                    placeholder={t('placeholder.select-class')}
                    clearable
                    onClear={() => form.resetField('classes')}
                    formatOption={(option, context) => (
                      <ClassOption
                        option={option}
                        classes={classes || []}
                        selectedClasses={selectedClasses}
                        optionContext={context}
                      />
                    )}
                  />
                )}
              />
            ) : null}
          </FormSection>
        ) : null}

        <FormButtons />
      </FormLayout>
    </Loading>
  )
}

const BasicInformationFormFields = (props: {
  form: UseFormReturn<UserForm>
}) => {
  const { t } = useTranslation('common')
  return (
    <FormSection header={t('header.basic-information')}>
      <FormField
        control={props.form.control}
        id="first-name"
        required
        label={t('label.first-name')}
        name="firstName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-first-name')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="last-name"
        required
        label={t('label.last-name')}
        name="lastName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-last-name')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="avh-number"
        required
        label={t('label.ahv-number')}
        name="ahvNumber"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-ahv-number')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="birth-date"
        label={t('label.birth-date')}
        name="birthDate"
        render={({ inputProps }) => (
          <DatePicker {...inputProps} className={styles.birthDate} />
        )}
      />
      <FormField
        control={props.form.control}
        id="gender"
        label={t('label.gender')}
        name="gender"
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            placeholder={t('placeholder.select-gender')}
            options={genderOptions}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="nationality"
        label={t('label.nationality')}
        name="nationalities"
        render={({ inputProps }) => <NationalitySelect {...inputProps} />}
      />
      <FormField
        control={props.form.control}
        id="first-language"
        label={t('label.first-language')}
        name="firstLanguage"
        render={({ inputProps }) => <LanguageSelect {...inputProps} />}
      />
      <FormField
        control={props.form.control}
        id="community"
        label={t('label.community')}
        name="community"
        render={({ inputProps }) => <CommunitySelect {...inputProps} />}
      />
    </FormSection>
  )
}

type ClassOptionProps = {
  option: SelectOption<string>
  classes: Class[]
  selectedClasses?: string[]
  optionContext: OptionContext
}

const ClassOption = (props: ClassOptionProps) => {
  const classOption = props.classes?.find(
    item => item.id === props.option.value
  )

  return (
    <div
      className={clsx(
        styles.classOption,
        props.selectedClasses?.includes(props.option.value) &&
          props.optionContext === 'menu' &&
          styles.classOptionSelected
      )}
    >
      <span>{props.option.label}</span>
      {props.optionContext === 'menu' ? (
        <span className={styles.tutor}>{classOption?.tutor?.fullName}</span>
      ) : null}
    </div>
  )
}

export default UserForm

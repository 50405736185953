import { type DefaultValues, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DatePicker from '@/components/DatePicker/DatePicker'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputPhoneNumber from '@/components/InputPhoneNumber/InputPhoneNumber'
import Select from '@/components/Select/Select'
import SendActivationLinkFormField from '@/components/common/ActivationLinkFormField/SendActivationLinkFormField'
import { CommunitySelect } from '@/components/common/CommunitySelect'
import {
  FormButtons,
  FormLayout,
  FormSection
} from '@/components/common/FormGrid/FormGrid'
import { LanguageSelect } from '@/components/common/LanguageSelect'
import { NationalitySelect } from '@/components/common/NationalitySelect'
import genderOptions from '@/constants/gender-options'
import useExternalErrors from '@/hooks/useExternalErrors'
import type { FormError } from '@/types/form-error'
import type { UserStatus } from '@/types/user-status'
import { validateEmailUniqueness } from '@/utils/validate-email-uniqueness'

import AcademicInformation from './AcademicInformation'
import styles from './StudentForm.module.scss'
import { type StudentForm } from '../constants/student-payload'

type StudentFormProps = {
  onSubmit: (data: StudentForm) => void
  defaultValues: DefaultValues<StudentForm>
  formErrors?: FormError<StudentForm>[]
  userStatus?: UserStatus
  loading: boolean
  form: UseFormReturn<StudentForm>
  children: React.ReactNode
}

const StudentForm = (props: StudentFormProps) => {
  useExternalErrors(props.formErrors, props.form)

  return (
    <FormLayout form={props.form} onSubmit={props.onSubmit}>
      <BasicInformationFormFields
        form={props.form}
        userStatus={props.userStatus}
      />
      <ContactDetails form={props.form} defaultValues={props.defaultValues} />
      <AcademicInformation form={props.form} />
      {props.children}
      <FormButtons />
    </FormLayout>
  )
}

type ContactDetailsProps = {
  form: UseFormReturn<StudentForm>
  defaultValues: DefaultValues<StudentForm>
}

const ContactDetails = (props: ContactDetailsProps) => {
  const { t } = useTranslation('students')
  return (
    <FormSection header={t('header.contact-details')}>
      <FormField
        control={props.form.control}
        id="email"
        required
        label={t('label.email')}
        name="email"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            onBlur={() => {
              inputProps.onBlur?.()
              setTimeout(() => {
                validateEmailUniqueness({
                  field: 'email',
                  form: props.form,
                  initialValue: props.defaultValues.email
                })
              }, 0)
            }}
            placeholder={t('placeholder.type-email')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="phone-number"
        label={t('label.phone-number')}
        name="phoneNumber"
        render={({ inputProps }) => <InputPhoneNumber {...inputProps} />}
      />
    </FormSection>
  )
}

const BasicInformationFormFields = (props: {
  form: UseFormReturn<StudentForm>
  userStatus?: UserStatus
}) => {
  const { t } = useTranslation('common')
  return (
    <FormSection header={t('header.basic-information')}>
      <FormField
        control={props.form.control}
        id="first-name"
        required
        label={t('label.first-name')}
        name="firstName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-first-name')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="last-name"
        required
        label={t('label.last-name')}
        name="lastName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-last-name')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="avh-number"
        required
        label={t('label.ahv-number')}
        name="ahvNumber"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-ahv-number')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="birth-date"
        label={t('label.birth-date')}
        name="birthDate"
        required
        render={({ inputProps }) => (
          <DatePicker {...inputProps} className={styles.birthDate} />
        )}
      />
      <FormField
        control={props.form.control}
        id="gender"
        label={t('label.gender')}
        name="gender"
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            placeholder={t('placeholder.select-gender')}
            options={genderOptions}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="nationality"
        label={t('label.nationality')}
        name="nationalities"
        render={({ inputProps }) => <NationalitySelect {...inputProps} />}
      />
      <FormField
        control={props.form.control}
        id="first-language"
        label={t('label.first-language')}
        name="firstLanguage"
        render={({ inputProps }) => <LanguageSelect {...inputProps} />}
      />
      <FormField
        control={props.form.control}
        id="community"
        label={t('label.community')}
        name="community"
        render={({ inputProps }) => <CommunitySelect {...inputProps} />}
      />
      <SendActivationLinkFormField
        control={props.form.control}
        id="send-activation-link"
        name="sendActivationLink"
        userStatus={props.userStatus}
      />
    </FormSection>
  )
}

export default StudentForm

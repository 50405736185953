import { getRouteApi } from '@tanstack/react-router'

import { type SelectOption } from '@/components/Select/Select'
import Filters from '@/components/common/Filters/Filters'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useAuthStore from '@/store/useAuthStore'
import { getValue } from '@/utils/get-value'

import CoursesList from '../components/CoursesList'
import type { CoursesFiltersKey } from '../constants/courses-filters'
import useFilters from '../hookes/useFilters'
import useCoursesGroups from '../queries/useCoursesGroups'

const routeApi = getRouteApi('/_auth/courses/my-courses')

const MyCoursesView = () => {
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const params = {
    ...search,
    ids: search.ids?.map(getValue),
    teacherOrCoTeacherId: search.teacherOrCoTeacherId?.map(getValue),
    studentsId: search.studentsId?.map(getValue),
    classesId: search.classesId?.map(getValue),
    coursesId: search.coursesId?.map(getValue)
  }

  const user = useAuthStore(store => store.user)

  const { data: groups, isLoading: isGroupsLoading } = useCoursesGroups({
    ...params,
    teacherOrCoTeacherId: user ? [user.id] : undefined
  })

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleChangeFilter = (
    key: CoursesFiltersKey,
    value?: string | string[] | SelectOption<string>[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        semester: search.semester,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const handleChangeSemester = (semesterId?: string) => {
    navigate({
      search: {
        semester: semesterId,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { filters, isSomeFilterSelected } = useFilters({
    ...search,
    onChangeFilter: handleChangeFilter,
    onChangeSemester: handleChangeSemester,
    hideTeacherFilter: true
  })

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <CoursesList
        page={search.page}
        pageSize={search.pageSize}
        semesterId={search.semester}
        list={groups?.list || []}
        count={groups?.count || 0}
        loading={isGroupsLoading}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
      />
    </>
  )
}
export default MyCoursesView

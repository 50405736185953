import { getRouteApi } from '@tanstack/react-router'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@/assets/icons/add.svg?react'
import Cancel from '@/assets/icons/cancel.svg?react'
import Edit from '@/assets/icons/edit.svg?react'
import MoreOutlined from '@/assets/icons/menu-vertical.svg?react'
import SendLink from '@/assets/icons/send-link.svg?react'
import Unlock from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import ConfirmModal from '@/components/ConfirmModal'
import DataBlock from '@/components/DataBlock/DataBlock'
import Dropdown, { DropdownMenuItem } from '@/components/Dropdown/Dropdown'
import LinkText from '@/components/LinkText/LinkText'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import ActionsDrawer from '@/components/common/ActionsDrawer/ActionsDrawer'
import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'
import { formatDate } from '@/utils/format-date'
import { userStatusTags } from '@/utils/user-status-tags'

import { AddGuardianModal } from './AddGuardianModal'
import { EditGuardianModal } from './EditGuardianModal'
import styles from './StudentDetailsView.module.scss'
import useActivateParent from '../mutations/useActivateParent'
import useAssignParent from '../mutations/useAssignParent'
import useBlockParent from '../mutations/useBlockParent'
import useSendActivationLinkParent from '../mutations/useSendActivationLinkParent'
import useStudent, { type Parent } from '../queries/useStudent'
import { getParentStatusTootlipText } from '../utils/statuses'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/$studentId/details'
)

const StudentDetailsView = () => {
  const { t } = useTranslation(['students'])

  const { studentId } = routeApi.useParams()

  const { data: student, refetch: refetchStudentDetails } =
    useStudent(studentId)

  const [currentParent, setCurrentParent] = useState<Parent | null>(null)

  const { mutate: blockParent, isPending: isBlockParentLoading } =
    useBlockParent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-blocked', { NAME: currentParent?.fullName })
        })
        setOpenBlockParentConfirmModal(false)
        refetchStudentDetails()
        setCurrentParent(null)
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-blocked-failed', {
            NAME: currentParent?.fullName
          })
        })
        setOpenBlockParentConfirmModal(false)
        setCurrentParent(null)
      }
    })

  const { mutate: activateParent, isPending: isActivateParentLoading } =
    useActivateParent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-activate', {
            NAME: currentParent?.fullName
          })
        })
        setOpenActivateParentConfirmModal(false)
        refetchStudentDetails()
        setCurrentParent(null)
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-activate', {
            NAME: currentParent?.fullName
          })
        })
        setOpenActivateParentConfirmModal(false)
        setCurrentParent(null)
      }
    })

  const { mutate: assignParent } = useAssignParent({
    onSuccess: () => {
      refetchStudentDetails()
      toast({
        variant: 'success',
        title: t('toast.student-updated')
      })
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.failed-to-update-student')
      })
    }
  })

  const [openBlockParentConfirmModal, setOpenBlockParentConfirmModal] =
    useState(false)
  const [openActivateParentConfirmModal, setOpenActivateParentConfirmModal] =
    useState(false)

  const basicInformationInfo = [
    { label: t('label.gender'), value: capitalize(student?.gender) },
    { label: t('label.birth'), value: formatDate(student?.dateOfBirth) },
    {
      label: t('label.nationality'),
      value: student?.nationalities.map(item => item.label)
    },
    { label: t('label.first-language'), value: student?.firstLanguage?.label },
    { label: t('label.community'), value: student?.community?.label },
    { label: t('label.ahv-number'), value: student?.ahvNumber }
  ]

  const academicsInfo = [
    { label: t('label.year'), value: student?.year },
    {
      label: t('label.class'),
      value: student?.studentClass?.id ? (
        <LinkText
          size="medium"
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: student.studentClass.id
          }}
        >
          {student.studentClass.name}
        </LinkText>
      ) : null
    },
    { label: t('label.tutor'), value: student?.tutor?.fullName }
  ]

  const contactDetailsInfo = [
    { label: t('label.phone-number'), value: student?.phoneNumber },
    { label: t('label.email'), value: student?.email }
  ]

  const handleActivateParent = () => {
    if (!!currentParent) activateParent({ id: currentParent?.id })
  }

  const handleBlockParent = () => {
    if (!!currentParent) blockParent({ id: currentParent?.id })
  }

  const [isGuardianModalOpen, setIsGuardianModalOpen] = useState(false)

  return (
    <>
      <div className={styles.sectionWrapper}>
        <h2 className={styles.sectionHeader}>{t('header.details')}</h2>
        <div className={styles.dataBlocksWrapper}>
          <DataBlock
            header={t('header.basic-information')}
            infoArray={basicInformationInfo}
          />
          <DataBlock header={t('header.academics')} infoArray={academicsInfo} />
          <DataBlock
            header={t('header.contact-details')}
            infoArray={contactDetailsInfo}
          />
        </div>
      </div>

      <div className={styles.sectionWrapper}>
        <div className={styles.sectionHeaderWrapper}>
          <h2 className={styles.sectionHeader}>{t('header.guardians')}</h2>
          <Button
            variant="secondary"
            icon={<AddIcon />}
            onClick={() => setIsGuardianModalOpen(true)}
          >
            {t('button.add-guardian')}
          </Button>
          <AddGuardianModal
            open={isGuardianModalOpen}
            onOpenChange={setIsGuardianModalOpen}
            onSubmit={parent => {
              if (!student) return
              assignParent({
                studentId: student.id,
                payload: parent.id
                  ? { id: parent.id }
                  : {
                      email: parent.email,
                      first_name: parent.firstName,
                      last_name: parent.lastName,
                      phone_number: parent.phoneNumber,
                      send_activation_link: parent.sendActivationLink
                    }
              })
            }}
            emailsToExclue={student?.parents?.map(({ email }) => email)}
          />
        </div>
        <div className={styles.dataBlocksWrapper}>
          {student?.parents?.length ? (
            student?.parents?.map(parent => (
              <ParentDetails
                key={parent.id}
                parent={parent}
                refetch={refetchStudentDetails}
                blockParent={() => {
                  setCurrentParent(parent)
                  setOpenBlockParentConfirmModal(true)
                }}
                activateParent={() => {
                  setCurrentParent(parent)
                  setOpenActivateParentConfirmModal(true)
                }}
              />
            ))
          ) : (
            <div className={styles.noDataBox}>
              {t('text.no-guardians-assigned')}
            </div>
          )}
        </div>
      </div>

      <ConfirmModal
        id="block-parent-modal"
        header={t('header.block-parent')}
        subheader={t('help.want-to-block-parent', {
          PARENT: currentParent?.fullName
        })}
        confirmButton={
          <Button
            variant="danger"
            onClick={handleBlockParent}
            loading={isBlockParentLoading}
          >
            {t('button.block')}
          </Button>
        }
        open={openBlockParentConfirmModal}
        onOpenChange={setOpenBlockParentConfirmModal}
      />

      <ConfirmModal
        id="activate-parent-modal"
        header={t('header.activate-parent')}
        subheader={t('help.want-to-activate-parent', {
          PARENT: currentParent?.fullName
        })}
        confirmButton={
          <Button
            onClick={handleActivateParent}
            loading={isActivateParentLoading}
          >
            {t('button.activate')}
          </Button>
        }
        open={openActivateParentConfirmModal}
        onOpenChange={setOpenActivateParentConfirmModal}
      />
    </>
  )
}

type ParentDetailsProps = {
  parent: Parent
  refetch: () => void
  blockParent: () => void
  activateParent: () => void
}

const ParentDetails = (props: ParentDetailsProps) => {
  const { t } = useTranslation(['students', 'common'])
  const { user } = useAuthStore()

  const { mutate: sendActivationLinkParent } = useSendActivationLinkParent({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.successfully-send-activation-link')
      })
      props.refetch()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.failed-send-activation-link')
      })
    }
  })

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const parentInfo = [
    { label: t('label.phone-number'), value: props.parent.phoneNumber },
    { label: t('label.email'), value: props.parent.email },
    {
      label: t('label.status'),
      value: (
        <Tooltip
          trigger={<Tag {...userStatusTags[props.parent.status]} />}
          text={getParentStatusTootlipText(props.parent.status)}
        />
      )
    }
  ]

  const actions = user?.isSuperAdmin
    ? [
        {
          icon: <Edit className={styles.icon} />,
          text: t('button.edit'),
          onClick: () => {
            setIsEditModalOpen(true)
          }
        },
        {
          icon: <SendLink className={styles.icon} />,
          text:
            props.parent.status === 'active'
              ? t('button.resend-activation-link')
              : t('button.send-activation-link'),
          onClick: () => handleSendActivationLinkToParent(),
          hidden: props.parent.status === 'blocked'
        },
        {
          icon: <Cancel className={styles.icon} />,
          text: t('button.block-parent'),
          onClick: () => {
            props.blockParent()
          },
          isDanger: true,
          hidden: props.parent.status !== 'active'
        },
        {
          text: t('button.activate-parent'),
          icon: <Unlock className={styles.icon} />,
          onClick: () => {
            props.activateParent()
          },
          hidden: props.parent.status !== 'blocked'
        }
      ].filter(item => !item.hidden)
    : []

  const handleSendActivationLinkToParent = () => {
    sendActivationLinkParent({ id: props.parent.id })
  }

  return (
    <div className={styles.wrapper}>
      <DataBlock
        header={
          <LinkText
            to="/students-and-classes/students/parents/$parentId/details"
            params={{
              parentId: props.parent.id
            }}
          >
            <span className={styles.parentName}>{props.parent.fullName}</span>
          </LinkText>
        }
        infoArray={parentInfo}
      />

      {actions.length ? (
        <div className={styles.actions}>
          <ActionsDrawer actions={actions} onlyMobile />
          <Dropdown
            trigger={
              <ButtonIcon
                variant="secondary"
                className={styles.dropdownTrigger}
                size="small"
                ariaLabel={t('common:button.actions')}
              >
                <MoreOutlined />
              </ButtonIcon>
            }
          >
            {actions.map((action, index) => (
              <DropdownMenuItem
                onClick={action.onClick}
                key={index}
                variant={action.isDanger ? 'danger' : 'neutral'}
              >
                <span className={styles.icon}>{action.icon}</span>
                {action.text}
              </DropdownMenuItem>
            ))}
          </Dropdown>
        </div>
      ) : null}
      {isEditModalOpen ? (
        <EditGuardianModal
          parent={props.parent}
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSuccess={props.refetch}
        />
      ) : null}
    </div>
  )
}

export default StudentDetailsView

import { getRouteApi } from '@tanstack/react-router'
import {
  isAfter,
  isBefore,
  endOfISOWeek,
  format,
  startOfISOWeek
} from 'date-fns'
import { identity, isEmpty, omit, pickBy } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounceValue } from 'usehooks-ts'

import InfoCircleFilled from '@/assets/icons/info-circle-filled.svg?react'
import CalendarBox from '@/components/CalendarBox/CalendarBox'
import { type SelectOption } from '@/components/Select/Select'
import SplittedHeader from '@/components/SplittedHeader/SplittedHeader'
import Switch from '@/components/Switch/Switch'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import { API_DATE_FORMAT } from '@/constants/date-format'
import { classesQueryOptions } from '@/queries/classesQueryOptions'
import { coursesQueryOptions } from '@/queries/coursesQueryOptions'
import { roomsQueryOptions } from '@/queries/roomsQueryOptions'
import { studentsQueryOptions } from '@/queries/studentsQueryOptions'
import useSemesters from '@/queries/useSemesters'
import useTeacherOptionsPaginated from '@/queries/useTeacherOptionsPaginated'
import useAuthStore from '@/store/useAuthStore'

import styles from './TimetableView.module.scss'
import LessonEvent, {
  type LessonEventClickType,
  type LessonEventProps
} from '../components/LessonEvent'
import type { TimetableEvent } from '../constants/timetable-events'
import type { TimetableFiltersKey } from '../constants/timetable-filters'
import usePreviewExists from '../queries/usePreviewExists'
import useTimetableEvents from '../queries/useTimetableEvents'
import useTimetablePreviewEvents from '../queries/useTimetablePreviewEvents'

const routeApi = getRouteApi('/_auth/timetable/')

const getSplitSemesterName = (fullName: string) => {
  const lastSpaceIndex = fullName.lastIndexOf(' ')
  return {
    firstPart: fullName.substring(0, lastSpaceIndex),
    secondPart: fullName.substring(lastSpaceIndex + 1)
  }
}

const TimetableView = () => {
  const { t } = useTranslation(['timetable'])

  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const user = useAuthStore(store => store.user)

  const [previewEnabled, setPreviewEnabled] = useState(false)

  const isSomeFilterExist = !isEmpty(
    pickBy(omit(search, ['dateFrom', 'dateTo']), identity)
  )

  const searchParams = {
    ...search,
    student: search.student?.value,
    teacher: search.teacher?.value,
    course: search.course?.value,
    classId: search.classId?.value,
    room: search.room?.value
  }

  const { data: events, isFetching: isTimetableEventsLoading } =
    useTimetableEvents(searchParams, isSomeFilterExist && !previewEnabled)

  const { data: eventsPreview, isFetching: isTimetablePreviewEventsLoading } =
    useTimetablePreviewEvents(searchParams, isSomeFilterExist && previewEnabled)

  const { data: semesters } = useSemesters()
  const { data: previewExists } = usePreviewExists()

  const [teacherSearch, setTeacherSearch] = useDebounceValue('', 200)

  const {
    data: teacherOptions,
    isLoading: isTeachersLoading,
    fetchNextPage: fetchNextTeacherOptions
  } = useTeacherOptionsPaginated({
    search: teacherSearch
  })

  const isUserTeacher = !!user?.isTeacher

  const sortedTeacherOptions = isUserTeacher
    ? [
        { label: user.fullName, value: user.id, isHighlighted: true },
        ...(teacherOptions || []).filter(option => option.value !== user.id)
      ]
    : teacherOptions || []

  const changeFilter = (
    key: TimetableFiltersKey,
    value?: string | SelectOption<string>
  ) => {
    navigate({
      search: () => ({
        [key]: value,
        dateFrom: searchParams.dateFrom,
        dateTo: searchParams.dateTo
      })
    })
  }

  const canSeeLessonDetails = (event: TimetableEvent) =>
    !!user?.isSuperAdmin ||
    (user?.id
      ? [
          event.teacher.id,
          event.teacherCover?.id,
          event.coTeacherCover?.id,
          event.coTeacher?.id,
          ...event.tutors
        ].includes(user.id)
      : false)
  const currentSemester = semesters?.list.find(
    semester =>
      !isBefore(searchParams.dateFrom, semester.startDate) &&
      !isAfter(searchParams.dateFrom, semester.endDate)
  )

  const handleEventClick = ({
    lessonId,
    courseId,
    groupId,
    isPreview
  }: LessonEventClickType) => {
    if (!isPreview && groupId) {
      navigate({
        to: '/timetable/$courseId/$groupId/lesson-details',
        params: {
          courseId,
          groupId
        },
        search: {
          lessonId
        }
      })
    }
  }

  const parseEventProps = (event: TimetableEvent): LessonEventProps => ({
    id: event.id,
    startDate: event.startDate,
    endDate: event.endDate,
    course: event.course,
    groupId: event.group?.id,
    teacherCoverName: event.teacherCover?.name,
    coTeacherCoverName: event.coTeacherCover?.name,
    teacherName: event.teacher.name,
    coTeacherName: event.coTeacher?.name,
    classNamesText: event.classNames.join(', '),
    roomName: event.room?.name,
    isCancelled: event.isCancelled,
    onClick: handleEventClick
  })

  const timetableEvents = previewEnabled
    ? eventsPreview?.map(event => ({
        start: event.startDate,
        end: event.endDate,
        dataTestId: 'lesson-preview-slot',
        component: <LessonEvent {...parseEventProps(event)} isPreview />
      })) || []
    : events?.map(event => ({
        start: event.startDate,
        end: event.endDate,
        dataTestId: 'lesson-slot',
        component: (
          <LessonEvent
            {...parseEventProps(event)}
            clickable={canSeeLessonDetails(event)}
          />
        )
      })) || []

  const handleOnDateChange = (date: Date) => {
    navigate({
      to: '/timetable',
      search: {
        ...search,
        dateFrom: format(startOfISOWeek(date), API_DATE_FORMAT),
        dateTo: format(endOfISOWeek(date), API_DATE_FORMAT)
      }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.teacher'),
      variant: 'select',
      filterProps: {
        id: 'teacher',
        loading: isTeachersLoading,
        options: sortedTeacherOptions,
        mode: 'detailed',
        value: search.teacher,
        placeholder: t('help.select-teacher'),
        onChange: value => changeFilter('teacher', value),
        onInputChange: setTeacherSearch,
        onMenuScrollToBottom: () => fetchNextTeacherOptions()
      }
    },
    {
      label: t('label.student'),
      variant: 'async-select',
      filterProps: {
        id: 'student',
        queryOptions: studentsQueryOptions,
        value: search.student,
        placeholder: t('help.select-student'),
        onChange: value => changeFilter('student', value)
      }
    },
    {
      label: t('label.course'),
      variant: 'async-select',
      filterProps: {
        id: 'course',
        queryOptions: coursesQueryOptions,
        value: search.course,
        placeholder: t('help.select-course'),
        onChange: value => changeFilter('course', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'async-select',
      filterProps: {
        id: 'group',
        queryOptions: classesQueryOptions,
        value: search.classId,
        placeholder: t('help.select-class'),
        onChange: value => changeFilter('classId', value)
      }
    },
    {
      label: t('label.room'),
      variant: 'async-select',
      filterProps: {
        id: 'room',
        queryOptions: roomsQueryOptions,
        value: search.room,
        placeholder: t('help.select-room'),
        onChange: value => changeFilter('room', value)
      }
    }
  ]

  return (
    <BasicLayout
      moduleName={t('header.academics', { ns: 'common' })}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>
            {currentSemester?.name ? (
              <SplittedHeader {...getSplitSemesterName(currentSemester.name)} />
            ) : (
              t('header.timetable')
            )}
          </h1>
          <div className={styles.previewWrapper}>
            <div className={styles.box}>
              <p className={styles.previewSwitchLabel}>
                {previewEnabled
                  ? t('label.preview-on')
                  : t('label.preview-off')}
              </p>

              <Switch
                ariaLabel={t('label.preview-enable')}
                id="preview"
                variant="header"
                value={previewEnabled}
                disabled={!previewExists}
                onChange={value => {
                  setPreviewEnabled(value)
                }}
              />

              <Tooltip
                renderTriggerAsChild
                text={t('help.timetable-preview')}
                trigger={
                  <button aria-label={t('help.more-about-preview')}>
                    <InfoCircleFilled className={styles.infoIcon} />
                  </button>
                }
              />
            </div>

            {previewExists ? (
              <Tag label={t('text.new-preview-available')} variant="on-blue" />
            ) : null}
          </div>
        </div>
      }
    >
      <Filters filters={filters} hideClearAllButton />

      <CalendarBox
        events={timetableEvents}
        loading={isTimetableEventsLoading || isTimetablePreviewEventsLoading}
        initialDate={searchParams.dateFrom}
        onDateChange={handleOnDateChange}
        height="auto"
      />
    </BasicLayout>
  )
}

export default TimetableView

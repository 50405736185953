import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ModalForm from '@/components/Modal/ModalForm'

type AvailabilityModalProps = {
  open: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const AvailabilityModal = (props: AvailabilityModalProps) => {
  const form = useForm()
  const { t } = useTranslation('availabilityManagement')
  return (
    <ModalForm
      id="availability-modal"
      form={form}
      onSubmit={() => {}}
      open={props.open}
      submitText={t('button.set')}
      title={t('header.set-availability')}
      onCancel={() => props.onOpenChange(false)}
      onClose={() => props.onOpenChange(false)}
      onOpenChange={props.onOpenChange}
      size="md"
    />
  )
}

export default AvailabilityModal

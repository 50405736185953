import type { Path } from 'react-hook-form'
import { z } from 'zod'

import type { FieldErrorsMapper } from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import {
  email,
  gender,
  labelAndValue,
  requiredStringWithLabel
} from '@/utils/zod'

import {
  parentFormfieldErrorsMapper,
  parentFormSchemaFields
} from './parent-payload'

export const guardianSchema = z.object({
  ...parentFormSchemaFields,
  id: z.string().optional()
})

export type GuardianForm = z.infer<typeof guardianSchema>

export const studentFormSchema = z
  .object({
    firstName: requiredStringWithLabel(
      i18n.t('label.first-name', { ns: 'users' })
    ),
    lastName: requiredStringWithLabel(
      i18n.t('label.last-name', { ns: 'users' })
    ),
    ahvNumber: requiredStringWithLabel(
      i18n.t('label.ahv-number', { ns: 'users' })
    ),
    birthDate: z.date(),
    gender: gender().optional(),
    nationalities: z.array(labelAndValue()),
    firstLanguage: labelAndValue().optional(),
    community: labelAndValue().optional(),
    email: email(i18n.t('label.email', { ns: 'students' })),
    phoneNumber: z.string(),
    sendActivationLink: z.boolean(),
    year: z.string().optional(),
    class: z.string().optional(),
    guardians: z.array(guardianSchema)
  })
  .superRefine((student, ctx) => {
    let isStudentEmailDuplicated = false

    student.guardians.forEach((guardian, index) => {
      const emailCount = student.guardians.filter(
        item => item.email === guardian.email
      ).length

      const isEmailSameAsStudent = guardian.email === student.email

      if (isEmailSameAsStudent || emailCount > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t('help.email-should-be-unique', { ns: 'students' }),
          path: ['guardians', index, 'email']
        })
      }

      if (isEmailSameAsStudent) isStudentEmailDuplicated = true
    })

    if (isStudentEmailDuplicated)
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t('help.email-should-be-unique', { ns: 'students' }),
        path: ['email']
      })
  })

export type StudentForm = z.infer<typeof studentFormSchema>

export const studentErrorsMapper: FieldErrorsMapper<StudentForm> = attr => {
  const studentFieldMap: Record<string, Path<StudentForm>> = {
    email: 'email',
    phone_number: 'phoneNumber',
    first_name: 'firstName',
    last_name: 'lastName',
    ahv_number: 'ahvNumber',
    date_of_birth: 'birthDate',
    gender: 'gender',
    nationalities: 'nationalities',
    first_language: 'firstLanguage',
    community: 'community',
    send_activation_link: 'sendActivationLink'
  }
  const mappedStudentField = studentFieldMap[attr]
  if (mappedStudentField) return mappedStudentField

  const [fieldName, index, key] = attr.split('.')
  if (fieldName === 'parents') {
    const mappedGuardianField = parentFormfieldErrorsMapper[key]
    if (mappedGuardianField)
      return `guardians.${Number(index)}.${mappedGuardianField}`
  }

  return 'root'
}

export const guardianFormDefaultValues: GuardianForm = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  sendActivationLink: false
}

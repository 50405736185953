import { zodResolver } from '@hookform/resolvers/zod'
import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useFieldArray, useForm, type FieldArrayWithId } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AddIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import Loading from '@/components/Loading'
import Table, { type Column } from '@/components/Table/Table'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { FormSectionHeader } from '@/components/common/FormGrid/FormGrid'
import { toast } from '@/hooks/useToast'
import { getFullName } from '@/utils/get-full-name'

import { AddGuardianModal } from './AddGuardianModal'
import styles from './StudentEditView.module.scss'
import StudentForm from '../components/StudentForm'
import {
  studentFormSchema,
  type StudentForm as StudentFormType
} from '../constants/student-payload'
import useEditStudent from '../mutations/useEditStudent'
import useStudent, { type Student } from '../queries/useStudent'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/edit/$studentId'
)

const StudentEditView = () => {
  const { t } = useTranslation(['students', 'common'])
  const { studentId } = routeApi.useParams()

  const { data, isLoading } = useStudent(studentId)

  return (
    <BasicLayout
      header={t('header.edit-student')}
      moduleName={t('common:header.academics')}
    >
      <Loading spinning={isLoading}>
        {data ? <StudentEditForm student={data} /> : null}
      </Loading>
    </BasicLayout>
  )
}

type StudentAddFormProps = {
  student: Student
}

const StudentEditForm = (props: StudentAddFormProps) => {
  const { t } = useTranslation('students')

  const defaultValues: StudentFormType = {
    email: props.student.email,
    firstName: props.student.firstName,
    lastName: props.student.lastName,
    ahvNumber: props.student.ahvNumber ?? '',
    birthDate: props.student.dateOfBirth,
    firstLanguage: props.student.firstLanguage,
    nationalities: props.student.nationalities,
    gender: props.student.gender,
    community: props.student.community,
    phoneNumber: props.student.phoneNumber ?? '',
    sendActivationLink: false,
    year: props.student.year,
    class: props.student.studentClass?.id,
    guardians:
      props.student.parents?.map(data => ({
        ...data,
        sendActivationLink: false
      })) ?? []
  }

  const form = useForm<StudentFormType>({
    resolver: zodResolver(studentFormSchema),
    mode: 'onBlur',
    defaultValues
  })

  const navigate = routeApi.useNavigate()
  const { studentId } = routeApi.useParams()

  const {
    mutate: editStudent,
    isPending,
    formErrors
  } = useEditStudent({
    id: studentId,
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.student-updated')
      })
      navigate({
        to: '/students-and-classes/students/$studentId/details',
        params: { studentId }
      })
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.failed-to-update-student')
      })
    }
  })

  const { remove, append, fields } = useFieldArray({
    control: form.control,
    name: 'guardians',
    keyName: '_id'
  })
  const [isGuardianModalOpen, setIsGuardianModalOpen] = useState(false)

  return (
    <>
      <StudentForm
        form={form}
        formErrors={formErrors}
        onSubmit={editStudent}
        defaultValues={defaultValues}
        loading={isPending}
      >
        <div className={styles.studentParentsForm}>
          <FormSectionHeader>{t('header.guardians')}</FormSectionHeader>
          <StudentParentsTable parents={fields} onRemove={remove} />
          <Button
            variant="secondary"
            icon={<AddIcon />}
            onClick={() => setIsGuardianModalOpen(true)}
          >
            {t('button.add-guardian')}
          </Button>
        </div>
      </StudentForm>
      <AddGuardianModal
        open={isGuardianModalOpen}
        onOpenChange={setIsGuardianModalOpen}
        onSubmit={append}
        emailsToExclue={fields.map(({ email }) => email)}
      />
    </>
  )
}

type StudentParentsTable = {
  onRemove: (index: number) => void
  parents: FieldArrayWithId<StudentFormType, 'guardians', '_id'>[]
}

const StudentParentsTable = (props: StudentParentsTable) => {
  const { t } = useTranslation('students')
  const columns: Column<
    FieldArrayWithId<StudentFormType, 'guardians', '_id'>
  >[] = [
    {
      key: 'name',
      title: t('header.name'),
      render: parent => getFullName(parent)
    },
    {
      key: 'email',
      title: t('header.email'),
      dataIndex: 'email'
    },
    {
      key: 'phone',
      title: t('header.phone-number'),
      dataIndex: 'phone'
    },
    {
      key: 'remove-student',
      width: 40,
      render: (_, __, index) => (
        <ButtonIcon
          size="medium"
          variant="tertiary"
          ariaLabel={t('button.remove-guardian')}
          onClick={() => props.onRemove(index)}
        >
          <DeleteIcon />
        </ButtonIcon>
      )
    }
  ]

  return (
    <Table
      id="student-parents"
      rowKey="_id"
      data={props.parents}
      columns={columns}
    />
  )
}

export default StudentEditView

import { useTranslation } from 'react-i18next'

import MoreOutlinedIcon from '@/assets/icons/menu-vertical.svg?react'
import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './TableTopBarActions.module.scss'
import Button from '../Button/Button'
import ActionsDrawer from '../common/ActionsDrawer/ActionsDrawer'

type Action = {
  text: string
  icon: React.ReactNode
  onClick: () => void
}

type Props = {
  actions: Action[]
  selectedItemsLength: number
  count: number
}

const TableTopBarActions = ({ actions, count, selectedItemsLength }: Props) => {
  const { t } = useTranslation('common')
  const { isMobile } = useScreenResolution()

  return (
    <div className={styles.headerTable}>
      {selectedItemsLength > 0 &&
        (isMobile && actions.length > 1 ? (
          <ActionsDrawer
            actions={actions}
            triggerButton={
              <Button
                variant="tertiary"
                size="small"
                icon={<MoreOutlinedIcon />}
              >
                {t('button.actions', { ns: 'common' })}
              </Button>
            }
            onlyMobile
          />
        ) : (
          <>
            {actions.map((action, index) => (
              <Button
                key={index}
                variant="tertiary"
                size="small"
                icon={action.icon}
                onClick={action.onClick}
              >
                {action.text}
              </Button>
            ))}
          </>
        ))}
      <span className={styles.topBarText}>
        {t('help.selected-text', {
          SELECTED_COUNT: selectedItemsLength,
          ALL_COUNT: count
        })}
      </span>
    </div>
  )
}

export default TableTopBarActions

import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDebounceValue } from 'usehooks-ts'
import { z } from 'zod'

import PlusIcon from '@/assets/icons/add.svg?react'
import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import ModalForm from '@/components/Modal/ModalForm'
import Select from '@/components/Select/Select'
import { toast } from '@/hooks/useToast'
import i18n from '@/i18n'
import useStudentOptions from '@/queries/useStudentOptions'
import { requiredStringWithLabel } from '@/utils/zod'

import styles from './AddStudentToParentModal.module.scss'
import useAssignParent from '../mutations/useAssignParent'

export const addStudentToParentForm = z.object({
  student: requiredStringWithLabel(i18n.t('label.student', { ns: 'students' }))
})

type FormPayload = z.infer<typeof addStudentToParentForm>

type AddStudentToParentProps = {
  parentId: string
  currentStudents: string[]
  onAddStudent?: () => void
}

const AddStudentToParentModal = (props: AddStudentToParentProps) => {
  const { t } = useTranslation('students')

  const [open, setOpen] = useState(false)
  const [studentsSearch, setStudentsSearch] = useDebounceValue('', 300)

  const { data: studentOptions, isLoading: isStudentsLoading } =
    useStudentOptions({ search: studentsSearch, pageSize: 50 })

  const filteredOptions =
    studentOptions?.filter(
      student => !props.currentStudents.includes(student.value)
    ) || []

  const { mutate: assignParent, isPending: isAssignParentPending } =
    useAssignParent({
      onSuccess: () => {
        toast({ variant: 'success', title: t('toast.guardian-updated') })
        setOpen(false)
        props.onAddStudent?.()
      },
      onError: () => {
        toast({ variant: 'error', title: t('toast.failed-guardian-update') })
        setOpen(false)
      }
    })

  const form = useForm<FormPayload>({
    resolver: zodResolver(addStudentToParentForm),
    mode: 'all',
    defaultValues: {
      student: ''
    }
  })

  const handleOnCancel = () => {
    form.reset()
    setOpen(false)
  }
  const handleOnOpenChange = (openValue: boolean) => {
    setOpen(openValue)
    if (!openValue) form.reset()
  }

  const handleOnSubmit = (payload: FormPayload) => {
    assignParent({
      studentId: payload.student,
      payload: { id: props.parentId }
    })
  }

  return (
    <ModalForm
      id="add-student-modal"
      title={t('header.add-student')}
      size="xs"
      form={form}
      open={open}
      onOpenChange={handleOnOpenChange}
      loading={isAssignParentPending}
      submitText={t('button.save')}
      trigger={
        <Button variant="secondary" icon={<PlusIcon />}>
          {t('button.add-student')}
        </Button>
      }
      onSubmit={handleOnSubmit}
      onCancel={handleOnCancel}
      onClose={handleOnCancel}
    >
      <FormField
        control={form.control}
        id="student"
        required
        label={t('label.student')}
        name="student"
        className={styles.student}
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            options={filteredOptions}
            mountedInBody
            loading={isStudentsLoading}
            placeholder={t('placeholder.select-student')}
            onInputChange={setStudentsSearch}
          />
        )}
      />
    </ModalForm>
  )
}

export default AddStudentToParentModal

import { getRouteApi } from '@tanstack/react-router'
import clsx from 'clsx'
import { useMemo, type HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import Table, { type Column } from '@/components/Table/Table'
import useRowSelection from '@/hooks/useRowSelection'
import useAuthStore from '@/store/useAuthStore'

import styles from './AvailabilityTable.module.scss'
import { getDaysOfWeek } from '../../constants/days-of-week'
import type { AvailiblityList } from '../../queries/useAvailabilityList'
import AvailabilitySlot from '../AvailabilitySlot/AvailabilitySlot'

type AvailabilitySlotsProps = {
  slots: {
    id: string
    isOptional: boolean
    startTime: string
    endTime: string
  }[]
}

const AvailabilitySlots = ({ slots }: AvailabilitySlotsProps) => (
  <div className={styles.slotsContainer}>
    {slots.length ? (
      slots.map(slot => (
        <AvailabilitySlot
          key={slot.id}
          variant={slot.isOptional ? 'optional' : 'available'}
          startTime={slot.startTime}
          endTime={slot.endTime}
        />
      ))
    ) : (
      <AvailabilitySlot variant="unavailable" />
    )}
  </div>
)

type AvailiabilityTableProps = {
  data: AvailiblityList[]
  isLoading: boolean
  count: number
}

const routeApi = getRouteApi('/_auth/availability-management/')

const AvailiabilityTable = ({
  data,
  isLoading,
  count
}: AvailiabilityTableProps) => {
  const { t } = useTranslation('availabilityManagement')
  const navigate = routeApi.useNavigate()
  const { page, pageSize } = routeApi.useSearch()
  const { user } = useAuthStore()

  const rowSelection = useRowSelection({
    count,
    itemsToSelect: data.map(item => item.id)
  })

  const daysOfWeek = useMemo(() => getDaysOfWeek(), [])

  const tableColumns: Column<AvailiblityList>[] = [
    {
      key: 'user',
      cellDataTestId: 'username',
      width: 160,
      render: ({ username }: AvailiblityList) => <p>{username}</p>
    },
    ...daysOfWeek.map(({ value: day, label }) => ({
      key: day,
      title: label,
      cellDataTestId: day,
      width: 134,
      render: (value: AvailiblityList) => (
        <AvailabilitySlots slots={value[day]} />
      )
    }))
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const getUsernameByRowId = (rowId: string) =>
    data.find(item => item.id === rowId)?.username

  const tableComponents = {
    header: {
      wrapper: (headerProps: HTMLAttributes<HTMLTableSectionElement>) => (
        <thead {...headerProps} className={styles.header} />
      ),
      cell: (cellProps: HTMLAttributes<HTMLTableCellElement>) => (
        <th
          {...cellProps}
          className={clsx(cellProps.className, styles.headerCell)}
        />
      )
    },
    body: {
      row: (
        rowProps: HTMLAttributes<HTMLTableRowElement> & {
          'data-row-key': string
        }
      ) => (
        <tr
          {...rowProps}
          tabIndex={0}
          aria-label={t('label.open-user-availability-modal', {
            USER: getUsernameByRowId(rowProps['data-row-key'])
          })}
        />
      ),
      cell: (cellProps: HTMLAttributes<HTMLTableCellElement>) => (
        <td {...cellProps} className={clsx(cellProps.className, styles.cell)} />
      )
    }
  }

  return (
    <Table
      id="availability-table"
      data={data}
      columns={tableColumns}
      isLoading={isLoading}
      onRowClick={row => console.log('opens a modal', row)}
      rowSelection={
        user?.isSuperAdmin
          ? {
              selectedItems: rowSelection.selectedItems,
              allCheckboxValue: rowSelection.getSelectAllCheckboxValue(),
              count,
              labelHeader: t('label.select-all-users'),
              labelItem: row => t('label.select-user', { USER: row.username }),
              onSelect: rowSelection.onSelect,
              onSelectAll: rowSelection.onSelectAll,
              checkboxVariant: 'default'
            }
          : undefined
      }
      pagination={{
        count,
        pageSize,
        page
      }}
      onChangePageSize={handleChangePageSize}
      onChangePage={handleChangePage}
      components={tableComponents}
    />
  )
}

export default AvailiabilityTable

import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ModalForm from '@/components/Modal/ModalForm'

import { GuardianDetailsForm } from '../components/GuardianDetailsForm'
import {
  guardianFormDefaultValues,
  guardianSchema,
  type GuardianForm
} from '../constants/student-payload'

type AddGuardianModalProps = {
  open: boolean
  onOpenChange: (isOpen: boolean) => void

  onSubmit: (data: GuardianForm) => void
  emailsToExclue?: string[]
}
export const AddGuardianModal = (props: AddGuardianModalProps) => {
  const { t } = useTranslation('students')

  const form = useForm<GuardianForm>({
    resolver: zodResolver(guardianSchema),
    mode: 'onBlur',
    defaultValues: guardianFormDefaultValues
  })

  useEffect(() => {
    if (props.open) form.reset(guardianFormDefaultValues)
  }, [props.open, form])

  return (
    <ModalForm
      form={form}
      id="add-guardian-modal"
      onSubmit={data => {
        props.onSubmit(data)
        props.onOpenChange(false)
      }}
      open={props.open}
      submitText={t('button.save')}
      title={t('header.add-guardian')}
      onCancel={() => props.onOpenChange(false)}
      onClose={() => props.onOpenChange(false)}
      onOpenChange={props.onOpenChange}
    >
      <GuardianDetailsForm
        form={form}
        id="add-guardian"
        emailsToExclue={props.emailsToExclue}
      />
    </ModalForm>
  )
}

import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useYearsOptions = () =>
  useQuery({
    queryKey: ['panelClassesOptionsYearsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelClassesOptionsYearsList()

      return response.results
    }
  })

export default useYearsOptions

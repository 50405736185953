import { z } from 'zod'

const availabilitySlotSchema = z.object({
  id: z.string(),
  start_time: z.string(),
  end_time: z.string(),
  is_optional: z.boolean()
})

export const availabilitySlots = z.array(availabilitySlotSchema)

const formatTime = (time: string) => time.slice(0, 5) // Extracts "HH:MM" from "HH:MM:SS"

export const parseAvailabiliy = (items: z.infer<typeof availabilitySlots>) =>
  items.map(item => ({
    id: item.id,
    startTime: formatTime(item.start_time),
    endTime: formatTime(item.end_time),
    isOptional: item.is_optional
  }))

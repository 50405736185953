import { getRouteApi } from '@tanstack/react-router'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'

import EditIcon from '@/assets/icons/edit.svg?react'
import Button from '@/components/Button/Button'
import DataBlock from '@/components/DataBlock/DataBlock'
import LinkText from '@/components/LinkText/LinkText'
import AvailabilityBadge from '@/components/common/AvailabilityBadge/AvailabilityBadge'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import { USER_PROFILE } from '@/constants/user-profile'
import useAuthStore from '@/store/useAuthStore'
import { formatDate, getWeekDays } from '@/utils/format-date'

import styles from './UserDetailsView.module.scss'
import useUser from '../queries/useUser'
import {
  type AvailabilitySlot,
  useUserAvailability
} from '../queries/useUserAvailability'
import { useUserAvailabilitySemesters } from '../queries/useUserAvailabilitySemesters'

const routeApi = getRouteApi('/_auth/users/$userId/details')

const UserDetailsView = () => {
  const navigate = routeApi.useNavigate()
  const { userId } = routeApi.useParams()
  const { semesterId } = routeApi.useSearch()
  const { data: user } = useUser(userId)
  const { user: userStore } = useAuthStore()
  const { t } = useTranslation(['users'])

  const { data: semesters, isLoading: isSemesterLoading } =
    useUserAvailabilitySemesters({ userId })

  const { data: userAvailability } = useUserAvailability({
    userId,
    semester: semesterId
  })

  const getAvailability = (value?: AvailabilitySlot[]) => {
    if (!value || !value.length) {
      return (
        <span className={styles.availabilitySlot}>
          <AvailabilityBadge variant="unavailable" />
          <span className={styles.availabilityText}>
            {t('text.unavailable')}
          </span>
        </span>
      )
    }

    return (
      <div className={styles.availabilityContainer}>
        {value?.map(item => (
          <span key={item.id} className={styles.availabilitySlot}>
            <AvailabilityBadge
              variant={item.isOptional ? 'optional' : 'available'}
            />
            <span className={styles.availabilityText}>
              {item.startTime} - {item.endTime}
            </span>
          </span>
        ))}
      </div>
    )
  }

  const basicInformationInfo = [
    { label: t('label.gender'), value: capitalize(user?.gender) },
    { label: t('label.birth'), value: formatDate(user?.dateOfBirth) },
    {
      label: t('label.nationality'),
      value: user?.nationalities.map(item => item.label)
    },
    { label: t('label.first-language'), value: user?.firstLanguage?.label },
    { label: t('label.community'), value: user?.community?.label },
    {
      label: t('label.ahv-number'),
      value: user?.ahvNumber
    }
  ]

  const academicInfo = [
    {
      label: t('label.class-tutor'),
      value: user?.tutor?.classes.length ? (
        <>
          {user?.tutor?.classes.map(classItem => (
            <LinkText
              size="medium"
              key={classItem.id}
              to="/students-and-classes/classes/$classId/details"
              className={styles.classItem}
              params={{
                classId: classItem.id
              }}
            >
              {classItem.name}
            </LinkText>
          ))}
        </>
      ) : (
        '—'
      ),
      hidden: !user?.profiles.includes(USER_PROFILE.TUTOR)
    },
    {
      label: t('label.preferred-room'),
      value: user?.teacher?.preferredRooms?.map(room => room.name) || [],
      hidden:
        !user?.profiles.includes(USER_PROFILE.TUTOR) &&
        !user?.profiles.includes(USER_PROFILE.TEACHER)
    }
  ].filter(item => !item.hidden)

  const contactDetailsInfo = [
    { label: t('label.phone-number'), value: user?.phoneNumber },
    { label: t('label.email'), value: user?.email },
    { label: t('label.emergency-contact'), value: user?.emergencyPhoneNumber }
  ]

  const weekdays = getWeekDays()

  const scheduleInfo = [
    {
      label: weekdays[0],
      value: getAvailability(userAvailability?.monday)
    },
    {
      label: weekdays[1],
      value: getAvailability(userAvailability?.tuesday)
    },
    {
      label: weekdays[2],
      value: getAvailability(userAvailability?.wednesday)
    },
    {
      label: weekdays[3],
      value: getAvailability(userAvailability?.thursday)
    },
    {
      label: weekdays[4],
      value: getAvailability(userAvailability?.friday)
    },
    {
      label: weekdays[5],
      value: getAvailability(userAvailability?.saturday)
    },
    {
      label: weekdays[6],
      value: getAvailability(userAvailability?.sunday)
    }
  ]

  const handleChangeSemester = (semester: string) => {
    navigate({
      search: {
        semesterId: semester
      }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'select',
      size: 'wide',
      filterProps: {
        id: 'semester',
        loading: isSemesterLoading,
        options: semesters?.options || [],
        value: semesterId,
        placeholder: t('label.semester'),
        onChange: handleChangeSemester
      }
    }
  ]

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.details}>{t('header.details')}</p>
          {userStore?.isSuperAdmin ? (
            <Button
              asLink
              to="/users/edit/$userId"
              params={{ userId }}
              variant="secondary"
              icon={<EditIcon />}
            >
              {t('button.edit')}
            </Button>
          ) : null}
        </div>
        <div className={styles.wrapper}>
          <DataBlock
            header={t('header.basic-information')}
            infoArray={basicInformationInfo}
          />

          <DataBlock header={t('header.academics')} infoArray={academicInfo} />

          <DataBlock
            header={t('header.contact-details')}
            infoArray={contactDetailsInfo}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.details}>{t('header.availability')}</p>
          <Filters filters={filters} hideClearAllButton />
        </div>

        <DataBlock infoArray={scheduleInfo} />
      </div>
    </>
  )
}

export default UserDetailsView

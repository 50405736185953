import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Alert from '@/components/Alert/Alert'
import ModalForm from '@/components/Modal/ModalForm'
import { toast } from '@/hooks/useToast'

import { GuardianDetailsForm } from '../components/GuardianDetailsForm'
import { guardianSchema, type GuardianForm } from '../constants/student-payload'
import useEditParent from '../mutations/useEditParent'
import type { Parent } from '../queries/useStudent'

type EditGuardianModalProps = {
  parent: Parent
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const EditGuardianModal = (props: EditGuardianModalProps) => {
  const { t } = useTranslation('students')

  const defaultValues: GuardianForm = {
    email: props.parent.email,
    firstName: props.parent.firstName,
    lastName: props.parent.lastName,
    phoneNumber: props.parent.phoneNumber,
    sendActivationLink: false
  }

  const form = useForm<GuardianForm>({
    resolver: zodResolver(guardianSchema),
    mode: 'onBlur',
    defaultValues
  })

  const { mutate: editParent } = useEditParent({
    id: props.parent.id,
    onSuccess: () => {
      props.onSuccess()
      props.onClose()
      toast({ variant: 'success', title: t('toast.guardian-updated') })
    },
    onError: () => {
      toast({ variant: 'error', title: t('toast.failed-guardian-update') })
    }
  })

  return (
    <ModalForm
      form={form}
      id="edit-guardian-modal"
      onSubmit={editParent}
      open={props.open}
      submitText={t('button.save')}
      title={t('header.edit-guardian')}
      onCancel={props.onClose}
      onClose={props.onClose}
      onOpenChange={value => {
        if (!value) props.onClose()
      }}
    >
      <GuardianDetailsForm form={form} id="edit-guardian" />
      <Alert message={t('help.changing-guardian-data')} variant="info" />
    </ModalForm>
  )
}
